<template>
    <div class=" h-100-vh ">
        <div class="mt-5">
            <div class="  text-center  ">
                <h2 class="">
                    <strong v-html=" $t('dashboard.welcome.title') "></strong>
                </h2>
            </div>
            <div class="card-body">
                <div class="row justify-content-center ">
                    <div class="col-12 col-lg-6 col-xl-4 py-4 ">
                        <div class="card h-100  card-ecart">
                            <div class="card-header ">
                                <h5 v-text=" this.$t('dashboard.menu.stores')"> </h5>
                            </div>
                            <div class="card-body d-flex flex-column justify-content-between">
                                <p v-text="$t('dashboard.welcome.messages.stores')"> </p>
                                <div>
                                    <div class="row mb-5 justify-content-center">
                                        <div class="col-12 col-md-auto text-center ">
                                            <img src="@/assets/images/icons/welcome/stores.svg" class="img-contain-150 d-xl-none "
                                                alt="">
                                            <img src="@/assets/images/icons/welcome/stores.svg"
                                                class="img-contain-100 d-none d-xl-block " alt="">
                                        </div>
                                        <div class="col-12 col-lg-auto my-auto ">
                                            <ul class=" ">
                                                <li v-for=" (item,index) in list.stores " :key="index">
                                                    <p v-text=" item "> </p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <p class="text-center">
                                        <router-link to="/dashboard/stores" class="btn btn-success rounded-pill"
                                            v-text=" $t('dashboard.welcome.goTo') + ' ' + $t('dashboard.menu.stores').toLowerCase() ">
                                        </router-link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-lg-6 col-xl-4 py-4 ">
                        <div class="card h-100  card-ecart">
                            <div class="card-header ">
                                <h5 v-text=" this.$t('dashboard.menu.products') "> </h5>
                            </div>
                            <div class="card-body d-flex flex-column justify-content-between">
                                <p v-text="$t('dashboard.welcome.messages.products')"> </p>
                                <div class="">

                                    <div class="row mb-5 justify-content-center">
                                        <div class="col-12 col-md-auto text-center ">
                                            <img src="@/assets/images/icons/welcome/products.svg"
                                                class="img-contain-150 d-xl-none " alt="">
                                            <img src="@/assets/images/icons/welcome/products.svg"
                                                class="img-contain-100 d-none d-xl-block " alt="">
                                        </div>
                                        <div class="col-12 col-lg-auto my-auto ">
                                            <ul class=" ">
                                                <li v-for=" (item,index) in list.products " :key="index">
                                                    <p v-text=" item "> </p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <p class="text-center">
                                        <router-link class="btn btn-success rounded-pill" to="/dashboard/products"
                                            v-text=" $t('dashboard.welcome.goTo') + ' ' + $t('dashboard.menu.products').toLowerCase() ">
                                        </router-link>
                                    </p>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-lg-6 col-xl-4 py-4 ">
                        <div class="card h-100  card-ecart">
                            <div class="card-header ">
                                <h5 v-text=" this.$t('dashboard.menu.orders') "> </h5>
                            </div>
                            <div class="card-body d-flex flex-column justify-content-between">
                                <p v-text="$t('dashboard.welcome.messages.orders')"> </p>
                                <div>
                                    <div class="row mb-5 justify-content-center">
                                        <div class="col-12 col-md-auto text-center ">
                                            <img src="@/assets/images/icons/welcome/orders.svg" class="img-contain-150 d-xl-none "
                                                alt="">
                                            <img src="@/assets/images/icons/welcome/orders.svg"
                                                class="img-contain-100 d-none d-xl-block " alt="">
                                        </div>
                                        <div class="col-12 col-lg-auto my-auto ">
                                            <ul class=" ">
                                                <li v-for=" (item,index) in list.orders " :key="index">
                                                    <p v-text=" item "> </p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <p class="text-center">
                                        <router-link class="btn btn-success rounded-pill" to="/dashboard/orders"
                                            v-text=" $t('dashboard.welcome.goTo') + ' ' + $t('dashboard.menu.orders').toLowerCase() ">
                                        </router-link>
                                    </p>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-lg-6 col-xl-4 py-4 ">
                        <div class="card h-100  card-ecart ">
                            <div class="card-header ">
                                <h5 v-text=" this.$t('dashboard.menu.shipping') "> </h5>
                            </div>
                            <div class="card-body d-flex flex-column justify-content-between">
                                <p v-text="$t('dashboard.welcome.messages.shipping')"> </p>
                                <div>

                                    <div class="row mb-5 justify-content-center">
                                        <div class="col-12 col-md-auto text-center  ">
                                            <img src="@/assets/images/icons/welcome/shipment.svg"
                                                class="img-contain-150 d-xl-none " alt="">
                                            <img src="@/assets/images/icons/welcome/shipment.svg"
                                                class="img-contain-100 d-none d-xl-block " alt="">
                                        </div>
                                        <div class="col-12 col-lg-auto my-auto ">
                                            <ul class=" ">
                                                <li v-for=" (item,index) in list.shipping " :key="index">
                                                    <p v-text=" item "> </p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <p class="text-center">
                                        <router-link class="btn btn-success rounded-pill" to="/dashboard/shipping"
                                            v-text=" $t('dashboard.welcome.goTo') + ' ' + $t('dashboard.menu.shipping').toLowerCase() ">
                                        </router-link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6 col-xl-4 py-4 ">
                        <div class="card h-100  card-ecart">
                            <div class="card-header ">
                                <h5 v-text=" this.$t('dashboard.menu.payments') "></h5>
                            </div>
                            <div class="card-body d-flex flex-column justify-content-between">
                                <p v-text="$t('dashboard.welcome.messages.payments')"> </p>
                                <div>
                                    <div class="row mb-5 justify-content-center">
                                        <div class="col-12 col-md-auto text-center  ">
                                            <img src="@/assets/images/icons/welcome/payments.svg"
                                                class="img-contain-150 d-xl-none " alt="">
                                            <img src="@/assets/images/icons/welcome/payments.svg"
                                                class="img-contain-100 d-none d-xl-block " alt="">
                                        </div>
                                        <div class="col-12 col-lg-auto my-auto ">
                                            <ul class=" ">
                                                <li v-for=" (item,index) in list.payments " :key="index">
                                                    <p v-text=" item "> </p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <p class="text-center">
                                        <router-link class="btn btn-success rounded-pill" to="/dashboard/payments"
                                            v-text=" $t('dashboard.welcome.goTo') + ' ' + $t('dashboard.menu.payments').toLowerCase() ">
                                        </router-link>
                                    </p>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-lg-6 col-xl-4 py-4 ">
                        <div class="card h-100  card-ecart">
                            <div class="card-header ">
                                <h5 v-text=" this.$t('dashboard.menu.account') "></h5>
                            </div>
                            <div class="card-body d-flex flex-column justify-content-between">
                                <p v-text="$t('dashboard.welcome.messages.account')"> </p>
                                <div>

                                    <div class="row mb-5 justify-content-center">
                                        <div class="col-12 col-md-auto text-center  ">
                                            <img src="@/assets/images/icons/welcome/my-account.svg"
                                                class="img-contain-150 d-xl-none " alt="">
                                            <img src="@/assets/images/icons/welcome/my-account.svg"
                                                class="img-contain-100 d-none d-xl-block " alt="">
                                        </div>
                                        <div class="col-12 col-lg-auto my-auto ">
                                            <ul class=" ">
                                                <li v-for=" (item,index) in list.account " :key="index">
                                                    <p v-text=" item "> </p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <p class="text-center">
                                        <router-link class="btn btn-success rounded-pill" to="/dashboard/account"
                                            v-text=" $t('dashboard.welcome.goTo') + ' ' + $t('dashboard.menu.account').toLowerCase() ">
                                        </router-link>
                                    </p>

                                </div>

                            </div>
                        </div>

                    </div>

                </div>

            </div>
        </div>

    </div>
</template>

<script>
    export default {
        data() {
            return ({
                list: this.$t('dashboard.welcome.lsit'),
            })
        }
    }
</script>

<style lang="scss" scoped>
    .card-ecart {
        .card-header {
            text-align: center;
        }
        .card-body{
            ul{
                color: #2edd82;
            }
        }
    }
</style>